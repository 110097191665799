import { createContext } from 'react';

import dynamic from 'next/dynamic';
import { useToggle } from 'react-use';

import { findActiveMarket } from '@hultafors/shared/helpers';
import { LanguageData } from '@hultafors/shared/types';

const MarketPicker = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.MarketPicker)
);

export interface MarketPickerContextValue {
  isOpen: boolean;
  toggle(force?: boolean): void;
  activeMarket: LanguageData | null;
}

export const MarketPickerContext =
  createContext<MarketPickerContextValue | null>(null);

interface MarketPickerProviderProps {
  children?: React.ReactNode;
  label: string | null | undefined;
  markets: LanguageData[];
  active?: string;
  currentLocale?: string;
}

export const MarketPickerProvider: React.FC<MarketPickerProviderProps> = ({
  children,
  label,
  markets,
  active,
  currentLocale,
}) => {
  const [isOpen, toggle] = useToggle(false);

  // Used to get active market for displaying
  // the flag of the active market in the navbar.
  let activeMarket: LanguageData | null = null;

  if (active) {
    activeMarket = findActiveMarket(markets, active);
  }

  return (
    <MarketPickerContext.Provider value={{ activeMarket, isOpen, toggle }}>
      <MarketPicker
        label={label}
        markets={markets}
        isOpen={isOpen}
        toggle={toggle}
        active={active}
        currentLocale={currentLocale}
      />
      {children}
    </MarketPickerContext.Provider>
  );
};
